// Relevant related files:
//

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { OrgSelectInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import GenericNavigatorFacingIndex from '../../shared/index_pages/GenericNavigatorFacingIndex.jsx'
import './NavigatorResourceRecordIndex.scss'
import rubyConstants from '../../ruby_constants.js'
import {
  isAnywhereExceptPR,
  anywhere_except_pr_text,
} from '../../utilities/anywhere_except_pr.js'

const stateAbbrevsOrNat = (abbrevs) => {
  if (isAnywhereExceptPR(abbrevs)) return anywhere_except_pr_text
  return abbrevs
    .map((abbrev) =>
      abbrev === rubyConstants.State.NATIONAL
        ? rubyConstants.State.NATIONAL
        : abbrev
    )
    .join(', ')
}
const NavigatorResourceRecordIndex = ({
  all_navigator_resource_records,
  authenticated_api_send_to_phone_path,
  initial_filters,
  initial_order,
  initial_page,
  resource_types,
  on_starred_page = false,
}) => {
  // NOTE: filter drawers should now use the predicates (keys):
  // { name_eq_any: ['array', 'of', 'strings'], with_any_offerings: ['array', 'of', 'offering_keys'] }

  const topFilters = [
    {
      label: 'Resource Type(s)',
      key: 'tags_name_en_eq_any',
      InputComponent: OrgSelectInput,
      extraProps: { options: resource_types },
    },
  ]

  const orderItemsArray = [
    {
      directions: ['asc', 'desc'],
      key: 'name',
      labels: {
        asc: { long: 'Name: A-Z', short: 'Name: A-Z' },
        desc: { long: 'Name: Z-A', short: 'Name: Z-A' },
      },
      sortVal: (id) => all_navigator_resource_records[id].name,
    },
  ]

  const recordOptionsForLinkSharingForm = useMemo(
    () =>
      Object.values(all_navigator_resource_records).map((org) => [
        org.name,
        org.id,
      ]),
    [all_navigator_resource_records]
  )

  const tableColumns = [
    {
      header: 'Resource Name',
      cssClass: 'col-name',
      render: (org) => (
        <a href={`/navigator_resource_records/${org.id}`}>{org.name}</a>
      ),
      testId: 'col-resource-name',
    },
    {
      header: 'Services Offered',
      cssClass: 'col-services-offered',
      render: (org) => org.services_offered,
    },
    {
      header: 'State',
      cssClass: 'col-state',
      render: (org) => stateAbbrevsOrNat(org.states_served),
    },
    {
      header: 'Displayed On',
      cssClass: 'col-displayed-on',
      render: (org) => org.display_on_long_value,
    },
    {
      header: 'URL',
      cssClass: 'col-website',
      render: (org) =>
        org.website ? (
          <a href={org.website} target="_blank" rel="noreferrer">
            website
          </a>
        ) : (
          '-'
        ),
    },
  ]

  return (
    <div id="NavigatorResourceRecordIndex">
      <GenericNavigatorFacingIndex
        authenticated_api_send_to_phone_path={
          authenticated_api_send_to_phone_path
        }
        filter_drawer_options={{}}
        filterDrawers={null}
        hashOfRecords={all_navigator_resource_records}
        hasSelectableColumn
        initial_filters={initial_filters}
        initial_order={initial_order}
        initial_page={initial_page}
        orderItemsConstructor={() => orderItemsArray}
        pageTitle="Resources"
        recordOptionsForLinkSharing={recordOptionsForLinkSharingForm}
        recordType="NavigatorResourceRecord"
        tableColumns={tableColumns}
        topFilters={topFilters}
        onStarredPage={on_starred_page}
      />
    </div>
  )
}

export const NavigatorResourceRecordType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  services_offered: PropTypes.string.isRequired,
  states_served: PropTypes.arrayOf(PropTypes.string).isRequired,
  display_on_long_value: PropTypes.string.isRequired,
  website: PropTypes.string,
})

NavigatorResourceRecordIndex.propTypes = {
  all_navigator_resource_records: PropTypes.objectOf(
    NavigatorResourceRecordType
  ).isRequired, // eslint-disable-line react/forbid-prop-types
  authenticated_api_send_to_phone_path: PropTypes.string.isRequired,
  initial_filters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
}

export default NavigatorResourceRecordIndex
