/* eslint-disable no-use-before-define */ // allow "private" funcs at bottom

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import IndexOrderLinks from './IndexOrderLinks.jsx'
import IndexTable from './IndexTable.jsx'
import LinkSharingForm from '../link_sharing/LinkSharingForm.jsx'
import PaginationLinks from './PaginationLinks.jsx'
import { IndexState, useIndexStateSetup, perPage } from './IndexState'
import IndexFilters from './IndexFilters.jsx'
import { fetchResultsFromServer } from './fetch_results_from_server.js'
import ServerErrorMessage from './ServerErrorMessage.jsx'
import './GenericNavigatorFacingIndex.scss'

const GenericNavigatorFacingIndex = ({
  recordType,
  hashOfRecords,
  tableColumns,
  initial_order,
  initial_page,
  orderItemsConstructor,
  tieBreakerFunc,
  pageTitle,
  privateRecordTooltipText,
  onStarredPage = false,

  // send-to-phone related:
  authenticated_api_send_to_phone_path = null,
  hasSelectableColumn,

  // filter related:
  topFilters,
  filterDrawers,
  filter_drawer_options,
  initial_filters,
  onFilterChange = () => {},
  searchButtonDisabled,
  tooltipText = {},
}) => {
  // NOTE: filter drawers should now use the predicates (keys):
  // { name_eq_any: ['array', 'of', 'strings'], with_any_offerings: ['array', 'of', 'offering_keys'] }

  const [indexState, setIndexState] = IndexState.use({
    hashOfRecords,
    orderItemsConstructor,
    filterQueryParams: initial_filters,
    currentPage: initial_page,
    currentOrder: initial_order,
    recordType,
    tieBreakerFunc,
  })

  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(hashOfRecords).map((org) => [org.name, org.id]),
    [hashOfRecords]
  )

  const applyFilters = async (newFilters, isFirstRender = false) => {
    try {
      const serverData = await fetchResultsFromServer(
        indexState.baseUrl(),
        newFilters
      )
      setIndexState(
        IndexState.handleServerData(newFilters, serverData, isFirstRender)
      )
    } catch (error) {
      setIndexState(IndexState.setErrorMessage(error.message))
    }
  }

  useIndexStateSetup(applyFilters, indexState)

  return (
    <div className="GenericNavigatorFacingIndex">
      {onStarredPage ? <h2> {pageTitle} </h2> : <h1> {pageTitle} </h1>}
      {indexState.errorMessage && (
        <ServerErrorMessage
          clearFilters={() => applyFilters({})}
          filterQueryParams={indexState.filterQueryParams}
          message={indexState.errorMessage}
        />
      )}
      {!onStarredPage && (
        <IndexFilters
          applyFilters={applyFilters}
          currentFiltersFromServer={indexState.filterDescriptions}
          currentPage={indexState.currentPage}
          drawerFilters={filterDrawers}
          filterDrawerOptions={filter_drawer_options}
          filterQueryParams={indexState.filterQueryParams}
          onFilterChange={onFilterChange}
          searchButtonDisabled={searchButtonDisabled}
          tooltipText={tooltipText}
          topFilters={topFilters}
        />
      )}

      {indexState.paginatedOrderedScopedFilteredIds.size > 0 && (
        <div className="scopes-toggles-widgets-sorting">
          <div className="widgets">
            {!onStarredPage && authenticated_api_send_to_phone_path && (
              <LinkSharingForm
                authenticated_api_send_to_phone_path={
                  authenticated_api_send_to_phone_path
                }
                recordIds={[...indexState.selectedIds]}
                recordOptions={recordOptionsForLinkSharingForm}
                recordType={recordType}
              />
            )}
          </div>
          <div className="sorting">
            <IndexOrderLinks
              currentOrder={indexState.currentOrder}
              orderItems={indexState.orderItemsArray}
              setCurrentOrder={(o) => setIndexState(IndexState.setOrder(o))}
            />
          </div>
        </div>
      )}
      <div className="index-table-background">
        <IndexTable
          recordType={recordType}
          columns={tableColumns}
          hasSelectableColumn={hasSelectableColumn}
          onStarredPage={onStarredPage}
          privateRecordTooltipText={privateRecordTooltipText}
          records={indexState.paginatedOrderedScopedFilteredRecords()}
          selectedIds={indexState.selectedIds}
          setSelectedIds={(set) =>
            setIndexState(IndexState.setSelectedIds(set))
          }
        />
      </div>
      <PaginationLinks
        currentPage={indexState.currentPage}
        onPageChange={(newPage) => setIndexState(IndexState.setPage(newPage))}
        perPage={perPage}
        recordType={recordType}
        totalRecords={indexState.totalRecords()}
      />
    </div>
  )
}

/* eslint-disable react/require-default-props */
GenericNavigatorFacingIndex.propTypes = {
  recordType: PropTypes.oneOf([
    'Profile',
    'NavigatorResourceRecord',
    'NavigatorSupportOrg',
  ]).isRequired,
  hashOfRecords: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
  tableColumns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
  pageTitle: PropTypes.string,
  privateRecordTooltipText: PropTypes.string,
  onStarredPage: PropTypes.bool,

  // send-to-phone related:
  authenticated_api_send_to_phone_path: PropTypes.string,
  hasSelectableColumn: PropTypes.bool.isRequired,

  // filter related:
  filterDrawers: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  filter_drawer_options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initial_filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onFilterChange: PropTypes.func, // eslint-disable-line react/forbid-prop-types
  topFilters: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  orderItemsConstructor: PropTypes.func.isRequired,
  tieBreakerFunc: PropTypes.func,
}
/* eslint-enable react/require-default-props */

export default GenericNavigatorFacingIndex
