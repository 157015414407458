import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FilterDrawers from './FilterDrawers.jsx'
import CurrentFilters from './CurrentFilters.jsx'
import { TooltipAnchor, Tooltip } from '../Tooltip.jsx'
import './IndexFilters.scss'

function removeKeysFromObject(obj, keysToRemove) {
  let newObject = { ...obj }

  for (const key of [keysToRemove].flat()) {
    const { [key]: _, ...rest } = newObject // eslint-disable-line no-unused-vars
    newObject = rest
  }

  return newObject
}

const IndexFilters = ({
  applyFilters,
  currentFiltersFromServer,
  drawerFilters,
  filterErrors,
  filterQueryParams,
  onFilterChange = () => {},
  searchButtonDisabled,
  topFilters,
  tooltipText,
}) => {
  // store these inputs in local state, but don't add them to filters
  // (and thus re-render the list)
  // until the user clicks submit
  const [topFilterValues, setTopFilterValues] = useState(() => {
    const initialFilterValues = topFilters.map(({ key, defaultValue }) => {
      // Check if the key exists in filterQueryParams, if not, use defaultValue (if specified)
      const value =
        key in filterQueryParams ? filterQueryParams[key] : defaultValue
      return [key, value]
    })
    return Object.fromEntries(initialFilterValues)
  })

  // send topFilterValues back up to NavigatorSupportOrgIndex (before 'Search' button is pressed)
  useEffect(() => {
    if (topFilterValues) {
      onFilterChange(topFilterValues)
    }
  }, [topFilterValues, onFilterChange])

  const handleSearch = () => {
    let newFilters = { ...filterQueryParams }
    topFilters.forEach(({ key, defaultValue }) => {
      const value = topFilterValues[key]
      // if the value is the default value, don't add it to the filters
      if (value === defaultValue || !value) {
        newFilters = removeKeysFromObject(newFilters, key)
      } else {
        newFilters[key] = value
      }
    })
    applyFilters(newFilters)
  }

  const resetDefaultValue = (key) => {
    const filterObject = topFilters.find((filter) => filter.key === key)
    const defaultValue = filterObject ? filterObject.defaultValue : null

    if (defaultValue !== null) {
      setTopFilterValues((prev) => ({
        ...prev,
        [key]: defaultValue,
      }))
    }
  }

  const removeAllFilters = () => {
    Object.keys(filterQueryParams).forEach((key) => resetDefaultValue(key))
    applyFilters({})
  }

  const removeFilter = (key) => {
    const updatedFilters = removeKeysFromObject(filterQueryParams, key)

    resetDefaultValue(key)
    applyFilters(updatedFilters)
  }

  const handleSearchOnKeyDownEnter = (e) => {
    if (e.key !== 'Enter') return
    handleSearch()
    e.target.blur()
  }

  // currently, this is the only error we are handling
  const topFilterError = filterErrors?.location

  return (
    <div id="IndexFilters">
      <div className="top-container">
        <div className="filters-and-submit">
          {topFilters.map(({ label, key, InputComponent, extraProps }) => {
            let tooltipContent = null
            if (tooltipText) {
              if (key === 'to_state_filter') {
                tooltipContent = tooltipText['providerStateTooltipText']
              } else if (key === 'to_provider_filter') {
                tooltipContent = tooltipText['providerNameTooltipText']
              }
            }

            return (
              <TooltipAnchor
                key={key}
                placement="bottom"
                disabled={
                  !(
                    tooltipText &&
                    ((key === 'to_state_filter' &&
                      tooltipText['providerStateTooltipText']) ||
                      (key === 'to_provider_filter' &&
                        tooltipText['providerNameTooltipText']))
                  )
                }
              >
                <InputComponent
                  key={key}
                  onChange={(newValue) =>
                    setTopFilterValues((prev) => ({ ...prev, [key]: newValue }))
                  }
                  onKeyDown={handleSearchOnKeyDownEnter}
                  label={label}
                  value={topFilterValues[key]}
                  {...extraProps}
                />
                <Tooltip>{tooltipContent}</Tooltip>
              </TooltipAnchor>
            )
          })}
          <button
            disabled={searchButtonDisabled}
            onClick={handleSearch}
            type="button"
          >
            Search
          </button>
        </div>
        {!!topFilterError && <div className="errors">{topFilterError}</div>}
      </div>
      {drawerFilters?.length > 0 && (
        <FilterDrawers
          filterQueryParams={filterQueryParams}
          applyFilters={applyFilters}
          drawers={drawerFilters}
        />
      )}
      <CurrentFilters
        removeFilter={removeFilter}
        currentFilters={currentFiltersFromServer}
        removeAllFilters={removeAllFilters}
      />
    </div>
  )
}

IndexFilters.defaultProps = { topFilterError: '' }

IndexFilters.propTypes = {
  currentFiltersFromServer: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      displayValue: PropTypes.string,
    })
  ).isRequired,
  topFilterError: PropTypes.string,
  filterQueryParams: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  applyFilters: PropTypes.func.isRequired,
}

export default IndexFilters
