import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import usStates from '../../utilities/us_states.js'
import {
  OrgSelectInput,
  OrgArrayCheckboxInput,
  OrgArrayRadioInput,
  RADIO_ANY_VALUE,
} from './inputs/OrgInputs.jsx'
import { RuleToFrom } from './RuleToFrom.jsx'
import GenericNavigatorFacingIndex from '../../shared/index_pages/GenericNavigatorFacingIndex.jsx'
import { supportOrgRelevance } from '../../shared_navigate_ab_finder/supportOrgRelevance.ts'
import './NavigatorSupportOrgIndex.scss'

const orgStatusOptions = [
  {
    label: 'Any status',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Active organizations',
    value: 'active',
  },
  {
    label: 'Paused organizations',
    value: 'paused',
  },
]

const orgTypeOptions = [
  {
    label: 'Any type',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Funds only',
    value: 'Fund',
  },
  {
    label: 'PSOs only',
    value: 'PSO',
  },
  {
    label: 'Funds & PSOs only',
    value: 'Fund & PSO',
  },
]

const seekerContactOptions = [
  {
    label: 'Any contact',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Works directly with abortion seekers',
    value: 'allowed',
  },
  {
    label: "Doesn't work directly with abortion seekers",
    value: 'not_allowed',
  },
]

const usStateOptions = Object.entries(usStates)
  .slice(1) // remove "National"
  .map((x) => x.reverse())

const usStateOptionsPlusNational = Object.entries(usStates).map((x) =>
  x.reverse()
)

const ColStatus = ({ org }) => (
  <>
    <div className={`status-pill ${org.display_status.toLowerCase()}`}>
      {org.display_status}
    </div>
    {/^paused/i.test(org.display_status) && (
      <>
        {org.paused_services_resume_on && (
          <div>
            until {new Date(org.paused_services_resume_on).toLocaleDateString()}
          </div>
        )}
        {org.website_paused_note_en && <div>{org.website_paused_note_en}</div>}
      </>
    )}
  </>
)

const ColEligibility = ({ org, bannedStates, clinic_names }) => (
  <>
    {org.rules.map((rule) => (
      <RuleToFrom
        bannedStates={bannedStates}
        rule={{ ...rule, supportOrgId: org.id }}
        key={rule.id}
        clinic_names={clinic_names}
      />
    ))}
  </>
)

export const navigatorSupportOrgTopFilters = (
  isProvidersFilterEnabled,
  isToStateFilterEnabled,
  providerOptions,
  statesWithNoActiveClinics
) => [
  {
    label: 'Abortion Seeker State',
    key: 'from_state_filter',
    InputComponent: OrgSelectInput,
    extraProps: { options: usStateOptions },
  },
  {
    label: 'Provider State (Optional)',
    key: 'to_state_filter',
    InputComponent: OrgSelectInput,
    extraProps: {
      disabled: !isToStateFilterEnabled,
      isValueDisabled: (state) => statesWithNoActiveClinics.has(state), // disable states where there are no 'active' providers
      options: usStateOptionsPlusNational,
      placeholder: 'National',
    },
  },
  {
    label: 'Provider Name (Optional)',
    key: 'to_provider_filter',
    InputComponent: OrgSelectInput,
    extraProps: {
      options: providerOptions,
      disabled: !isProvidersFilterEnabled,
    },
  },
]

const NavigatorSupportOrgIndex = ({
  all_orgs,
  authenticated_api_send_to_phone_path,
  banned_states,
  clinic_names,
  partner_clinics,
  filter_drawer_options,
  initial_filters,
  initial_page,
  initial_order,
  on_starred_page = false,
  states_with_no_active_clinics,
}) => {
  // NOTE: filter drawers should now use the predicates (keys):
  // { name_eq_any: ['array', 'of', 'strings'], with_any_offerings: ['array', 'of', 'offering_keys'] }
  const bannedStates = new Set(banned_states)

  const statesWithNoActiveClinics = new Set(states_with_no_active_clinics)

  const [isToStateFilterEnabled, setIsToStateFilterEnabled] = useState(false)

  const [isProvidersFilterEnabled, setIsProvidersFilterEnabled] =
    useState(false)
  const [providerOptions, setProviderOptions] = useState(
    partner_clinics.map(({ id, formatted_name }) => [formatted_name, id])
  )

  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false)

  const onFilterChange = (topFilterValues) => {
    const abortionSeekerState = topFilterValues['from_state_filter']
    // disable 'To State' and 'Provider Name' dropdowns if 'From State' isn't selected
    setIsToStateFilterEnabled(!!abortionSeekerState)

    // disable 'Search' button if 'From State' isn't selected
    setSearchButtonDisabled(!abortionSeekerState)

    // disable 'Provider Name' dropdown until a 'To State' is selected
    const toState = topFilterValues['to_state_filter']

    if (toState && toState !== 'National') {
      setProviderOptions(
        partner_clinics.filter(({ states_served }) =>
          states_served.includes(toState)
        )
      )
      const newProviderOptions = allClinics
      console.log('telehealthClinics:', telehealthClinics)
      if (
        JSON.stringify(providerOptions) !== JSON.stringify(newProviderOptions)
      ) {
        setProviderOptions(newProviderOptions)
      }

      setIsProvidersFilterEnabled(true)
    } else {
      // eslint-disable-next-line no-param-reassign
      topFilterValues['to_provider_filter'] = null // clear provider filter if user selects 'National' as Provider State
      setIsProvidersFilterEnabled(false)
    }
  }

  const providerStateTooltipText =
    'To change provider state, please first select an abortion seeker state.'
  const providerNameTooltipText =
    'To change provider name, please first select an abortion seeker state and a provider state.'

  const tooltipText = {}
  if (isToStateFilterEnabled !== true) {
    tooltipText.providerStateTooltipText = providerStateTooltipText
  }
  if (isProvidersFilterEnabled !== true) {
    tooltipText.providerNameTooltipText = providerNameTooltipText
  }

  const filterDrawers = [
    // label, URL query param, filter drawer component, props for drawer component
    {
      label: 'Support Type(s)',
      key: 'with_any_offerings',
      InputComponent: OrgArrayCheckboxInput,
      extraProps: { options: filter_drawer_options.with_any_offerings },
    },
    {
      label: 'Status',
      key: 'org_status',
      InputComponent: OrgArrayRadioInput,
      extraProps: { name: 'org_status', options: orgStatusOptions },
    },
    {
      label: 'Organization Type',
      key: 'org_type_eq',
      InputComponent: OrgArrayRadioInput,
      extraProps: { name: 'org_type_eq', options: orgTypeOptions },
    },
    {
      label: 'Abortion Seeker Contact',
      key: 'seeker_contact_filter',
      InputComponent: OrgArrayRadioInput,
      extraProps: {
        name: 'seeker_contact_filter',
        options: seekerContactOptions,
      },
    },
    {
      label: 'Filter by Name',
      key: 'name_eq_any',
      InputComponent: OrgSelectInput,
      extraProps: {
        options: Object.values(all_orgs)
          .map((org) => org.name)
          .sort((a, b) =>
            a.localeCompare(b, undefined, { sensitivity: 'base' })
          ),
        isMulti: true,
      },
    },
  ]

  const orderItemsConstructor = (queryFilterParams) => [
    {
      directions: ['asc'],
      key: 'relevance',
      labels: {
        asc: { long: 'Relevance', short: 'Relevance' },
      },
      sortVal: (id) =>
        (queryFilterParams.from_state_filter
          ? supportOrgRelevance(all_orgs[id], {
              fromState: queryFilterParams.from_state_filter,
              toState: queryFilterParams.to_state_filter,
              toClinicId: queryFilterParams.to_provider_filter,
            })
          : 0) +
        // put all "not_allowed" orgs at the end
        (all_orgs[id].abortion_seeker_contact_preferences === 'not_allowed'
          ? 1000
          : 0),
    },
    {
      directions: ['asc', 'desc'],
      key: 'name',
      labels: {
        asc: { long: 'Name: A-Z', short: 'Name: A-Z' },
        desc: { long: 'Name: Z-A', short: 'Name: Z-A' },
      },
      sortVal: (id) => all_orgs[id].name,
    },
    {
      directions: ['asc', 'desc'],
      key: 'org_type',
      labels: {
        asc: { long: 'Type: A-Z', short: 'Type: A-Z' },
        desc: { long: 'Type: Z-A', short: 'Type: Z-A' },
      },
      sortVal: (id) => all_orgs[id].org_type,
    },
    {
      directions: ['asc', 'desc'],
      key: 'status',
      labels: {
        asc: { long: 'Status: A-Z', short: 'Status: A-Z' },
        desc: { long: 'Status: Z-A', short: 'Status: Z-A' },
      },
      sortVal: (id) => all_orgs[id].display_status,
    },
  ]

  const tableColumns = [
    {
      header: 'Support Organization Name',
      cssClass: 'col-name',
      render: (org) => (
        <a href={`/navigator_support_orgs/${org.id}`}>{org.name}</a>
      ),
      testId: 'col-org-name',
    },
    {
      header: (
        <div>
          <span>Eligibility: From</span>
          <span>Eligibility: To</span>
        </div>
      ),
      cssClass:
        'col-div_span_eligibility_from_span_span_eligibility_to_span_div',
      render: (org) => (
        <ColEligibility
          org={org}
          bannedStates={bannedStates}
          clinic_names={clinic_names}
        />
      ),
    },
    {
      header: 'Website',
      cssClass: 'col-website',
      render: (org) => (org.website ? <a href={org.website}>website</a> : '-'),
    },
    {
      header: 'Type',
      cssClass: 'col-type',
      render: (org) => org.org_type,
    },
    {
      header: 'contact?',
      cssClass: 'col-contact',
      render: (org) => org.abortion_seeker_contact_preferences,
    },
    {
      header: (
        <>
          <i>Navigate</i> <span>Users</span>
        </>
      ),
      cssClass: 'col-navigate-users',
      render: (org) =>
        org.profile_ids.length ? (
          <a
            href={`/admin/navigator_support_orgs/${org.id}#tab_navigate_users`}
          >
            Y
          </a>
        ) : (
          '-'
        ),
    },
    {
      header: 'Status',
      cssClass: 'col-status',
      render: (org) => <ColStatus org={org} />,
    },
  ]

  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(all_orgs).map((org) => [org.name, org.id]),
    [all_orgs]
  )

  return (
    <div id="NavigatorSupportOrgIndex">
      <GenericNavigatorFacingIndex
        authenticated_api_send_to_phone_path={
          authenticated_api_send_to_phone_path
        }
        filter_drawer_options={filter_drawer_options}
        filterDrawers={filterDrawers}
        hashOfRecords={all_orgs}
        hasSelectableColumn
        initial_filters={initial_filters}
        initial_order={initial_order}
        initial_page={initial_page}
        orderItemsConstructor={orderItemsConstructor}
        tieBreakerFunc={(id) => all_orgs[id].name}
        pageTitle="Support Organizations"
        privateRecordTooltipText="This is a private organization and cannot be shared"
        recordOptionsForLinkSharing={recordOptionsForLinkSharingForm}
        recordType="NavigatorSupportOrg"
        tableColumns={tableColumns}
        topFilters={navigatorSupportOrgTopFilters(
          isProvidersFilterEnabled,
          isToStateFilterEnabled,
          providerOptions,
          statesWithNoActiveClinics
        )}
        onFilterChange={onFilterChange}
        onStarredPage={on_starred_page}
        searchButtonDisabled={searchButtonDisabled}
        tooltipText={tooltipText}
      />
    </div>
  )
}

const OrgType = PropTypes.shape({
  private_record: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  website: PropTypes.string,
  displayStatus: PropTypes.oneOf(['Active', 'Paused']),
  rules: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
})

NavigatorSupportOrgIndex.propTypes = {
  all_orgs: PropTypes.objectOf(OrgType).isRequired,
  authenticated_api_send_to_phone_path: PropTypes.string.isRequired,
  filter_drawer_options: PropTypes.shape({
    with_any_offerings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  initial_filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ).isRequired,
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
}

export default NavigatorSupportOrgIndex
