// IndexState - Encapsulated state and functions for generic index pages, e.g. <NavigatorSupportOrgIndex>

/* eslint-disable no-use-before-define */ // allow "private" funcs at bottom

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { setOf } from '../../utilities/custom_prop_types.js'

export const perPage = 50
const urls = {
  NavigatorClinic: '/navigator_clinics',
  NavigatorSupportOrg: '/navigator_support_orgs',
  Profile: '/profiles',
  NavigatorResourceRecord: '/navigator_resource_records',
}

export const useIndexStateSetup = (applyFilters, indexState) => {
  useEffect(() => {
    applyFilters(indexState.filterQueryParams, true)
  }, [])

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 25)
  }, [indexState.currentPage])
}

export class IndexState {
  constructor({
    currentOrder,
    currentPage,
    currentScope,
    defaultOrderSortValFunc,
    errorMessage = null,
    filterErrors = null,
    filteredIds = null,
    filterDescriptions = [],
    filterQueryParams,
    hashOfRecords,
    orderItemsConstructor,
    recordType,
    scopes,
    selectedIds = null,
    tieBreakerFunc,
  }) {
    this.currentOrder = currentOrder || ''
    this.currentPage = currentPage
    this.currentScope = currentScope || ''
    this.defaultOrderSortValFunc = defaultOrderSortValFunc
    this.filterDescriptions = filterDescriptions
    this.filteredIds =
      filteredIds || Object.values(hashOfRecords).map((record) => record.id)
    this.filterErrors = filterErrors
    this.filterQueryParams = filterQueryParams
    this.hashOfRecords = hashOfRecords
    this.orderItemsConstructor = orderItemsConstructor
    this.orderItemsArray = orderItemsConstructor(filterQueryParams)
    this.recordType = recordType
    this.scopes = scopes
    this.selectedIds = selectedIds || new Set()
    this.errorMessage = errorMessage
    this.tieBreakerFunc = tieBreakerFunc

    this.#recalculate()
  }

  // Returns an instance, and a setter, like useState(), but with some stuff
  // installed, e.g. popstate handler
  static use(indexStateProps) {
    useEffect(() => {
      window.addEventListener('popstate', reloadPageOnPopState)

      return () => {
        window.removeEventListener('popstate', reloadPageOnPopState)
      }
    }, [])

    return useState(() => new this(indexStateProps))
  }

  //
  //    GETTER methods (they don't modify object)
  //

  // Return array of records that should be shown on this index page.
  paginatedOrderedScopedFilteredRecords() {
    this.#validate()
    const ids = [...this.paginatedOrderedScopedFilteredIds.values()]

    return ids.map((id) => this.hashOfRecords[id])
  }

  totalRecords() {
    return this.orderedScopedFilteredIds.length
  }

  baseUrl() {
    return urls[this.recordType]
  }

  //
  //    SETTER methods (they are static, curried functions that return new instance)
  //

  static setPage(pageNum) {
    return (oldState) => {
      const newState = oldState._clone({ currentPage: pageNum })
      newState._pushUrlHistory()
      return newState
    }
  }

  static setOrder(newOrder) {
    return (oldState) => {
      const newState = oldState._clone({
        currentPage: 1,
        currentOrder: newOrder,
      })
      newState._pushUrlHistory()
      return newState
    }
  }

  static setScope(newScope) {
    return (oldState) => {
      const newState = oldState._clone({
        currentPage: 1,
        currentScope: newScope,
      })
      newState._pushUrlHistory()
      return newState
    }
  }

  // selectedIds = Set of IDs that should be selected
  static setSelectedIds(selectedIds) {
    return (oldState) => oldState._clone({ selectedIds })
  }

  static setSelectedId(id, newVal) {
    return (oldState) => {
      const newSelectedIds = new Set(oldState.selectedIds)
      if (newVal) newSelectedIds.add(id)
      else newSelectedIds.delete(id)
      return oldState._clone({
        selectedIds: newSelectedIds,
      })
    }
  }

  static setErrorMessage(message) {
    return (oldState) => oldState._clone({ errorMessage: message || null })
  }

  static setOrderItemsConstructor(orderItemsConstructor) {
    return (oldState) => oldState._clone({ orderItemsConstructor })
  }

  // eslint-disable-next-line class-methods-use-this
  _adjustFromFilterServerData(_oldState, _serverData) {
    // NavigateClinicIndexState overrides this
  }

  static handleServerData(
    newFilterQueryParams,
    serverData,
    isFirstRender = false
  ) {
    return (oldState) => {
      const newState = oldState._clone({
        // make sure incoming serverData.ids are limited to ids we have a record for
        filteredIds: serverData.ids.filter((id) => oldState.hashOfRecords[id]),
        filterQueryParams: newFilterQueryParams,
        filterDescriptions: serverData.current_filters,
        filterErrors: serverData.filter_errors,
        errorMessage: null,
      })

      newState._adjustFromFilterServerData(oldState, serverData)
      if (!isFirstRender) {
        newState.currentPage = 1
        newState._pushUrlHistory()
        newState.#recalculate()
      }
      return newState
    }
  }

  //
  // Private methods
  //

  // Return a shallow copy of this object, with some values overridden.
  // Works for subclasses too.
  _clone(overrides) {
    return new this.constructor({
      ...this,
      ...overrides,
    })
  }

  // Modifies the object in-place. OK since this is a private method.
  #recalculate() {
    this.#validate()

    // Scoping
    const scopedFilteredIds = this.scopes?.[this.currentScope]
      ? this.filteredIds.filter((id) =>
          this.scopes[this.currentScope].ids.includes(id)
        )
      : this.filteredIds

    // Ordering
    const orderedScopedFilteredIds = scopedFilteredIds
    sortByOrder(
      orderedScopedFilteredIds,
      this.currentOrder,
      this.orderItemsArray,
      this.defaultOrderSortValFunc,
      this.tieBreakerFunc
    )

    // Pagination
    const paginatedOrderedScopedFilteredIds = calculatePaginatedIds(
      this.currentPage,
      orderedScopedFilteredIds
    )

    // Selection - Exclude selected if not on visible page
    const newSelectedIds = new Set(
      [...this.selectedIds].filter((id) =>
        paginatedOrderedScopedFilteredIds.has(id)
      )
    )

    // Update the state
    this.orderedScopedFilteredIds = orderedScopedFilteredIds
    this.paginatedOrderedScopedFilteredIds = paginatedOrderedScopedFilteredIds
    this.selectedIds = newSelectedIds

    this.#validate()
  }

  #validate() {
    const INDEX_STATE_SHAPE = {
      currentOrder: PropTypes.string,
      currentPage: PropTypes.number.isRequired,
      defaultOrderSortValFunc: PropTypes.func,
      filterDescriptions: PropTypes.array.isRequired,
      filterQueryParams: PropTypes.object.isRequired,
      filteredIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      hashOfRecords: PropTypes.objectOf(
        PropTypes.shape({ id: PropTypes.number })
      ).isRequired,
      orderItemsConstructor: PropTypes.func,
      recordType: PropTypes.oneOf(Object.keys(urls)),
      selectedIds: setOf(PropTypes.number),
      errorMessage: PropTypes.string,
      tieBreakerFunc: PropTypes.func,
    }

    PropTypes.checkPropTypes(INDEX_STATE_SHAPE, this, 'prop', 'IndexState')
  }

  _historyParams() {
    const params = {}
    if (this.filterQueryParams) params.q = this.filterQueryParams
    if (this.currentPage !== 1) params.page = this.currentPage
    if (this.currentOrder) params.order = this.currentOrder
    if (this.currentScope) params.scope = this.currentScope

    return params
  }

  _pushUrlHistory() {
    const qstring = queryString(this._historyParams())
    const url = `${this.baseUrl()}?${qstring}`
    window.history.pushState({ url }, '', url)
  }

  // Other private methods
}

// Additional functions
const queryString = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' })

const calculatePaginatedIds = (page, orderedScopedFilteredIds) => {
  const firstRecord = (page - 1) * perPage
  const lastRecord = firstRecord + perPage
  return new Set(orderedScopedFilteredIds.slice(firstRecord, lastRecord))
}

export const sortByOrder = (
  arrOfIds,
  orderKeyWithDir,
  orderItemsArray,
  defaultOrderSortValFunc,
  tieBreakerFunc
) => {
  if (!arrOfIds.length) return arrOfIds

  const [orderKey, orderDir] = (orderKeyWithDir || '').split(/_(?=[^_]+$)/)
  const orderItem = orderItemsArray.find((item) => item.key === orderKey)
  const dirMultiplier = orderDir === 'desc' ? -1 : 1
  const orderItemsHaveArrived = !!orderItemsArray?.length
  if (orderItemsHaveArrived && orderKey && !orderItem) {
    throw new Error(`Could not find orderItem with key ${orderKey}`)
  }
  const sortValFunc = orderItem ? orderItem.sortVal : defaultOrderSortValFunc
  if (sortValFunc) {
    const compare = (a, b, func) => {
      const type = typeof func(arrOfIds[0])
      return type === 'string'
        ? func(a).localeCompare(func(b), undefined, {
            sensitivity: 'base',
          })
        : func(a) - func(b)
    }
    const sortFunc = (a, b) => {
      const initialCompare = compare(a, b, sortValFunc)
      if (initialCompare === 0 && !!tieBreakerFunc) {
        return compare(a, b, tieBreakerFunc)
      }
      return initialCompare
    }
    arrOfIds.sort((a, b) => sortFunc(a, b) * dirMultiplier)
  }
  return arrOfIds
}

// handle browser Back and Forward buttons for JS generated history events
export const reloadPageOnPopState = (event) => {
  window.location.href = event.target.location
}
