// TODO: Refactor all the Select inputs to OrgSelectInput
// so we don't have to deal with formatting/unformatting the options

import './AreaInput.scss'
import PropTypes from 'prop-types'
import React from 'react'
import {
  OrgSelectInput,
  OrgCreatableSelectInput,
} from '../views/navigator_support_orgs/inputs/OrgInputs.jsx'
import toOption from '../utilities/to_option'
import usStates from '../utilities/us_states.js'
import rubyConstants from '../ruby_constants.js'

const NATIONAL = 'National'

const AreaInput = ({
  area,
  citiesByState,
  clinicsByState,
  disableRemoveStateButton,
  hideCoverageType,
  includeNational,
  isStateDisabled,
  regionsByState,
  stateLabel = 'State',
  updateArea,
}) => {
  const constants = rubyConstants.GeographicArea
  const usStateOptions = Object.entries(usStates)
    .slice(includeNational ? 0 : 1)
    .map((ele) => toOption(ele.reverse()))

  const coverageTypeOptions =
    area.state === NATIONAL
      ? constants.NATIONAL_COVERAGE_TYPES
      : constants.COVERAGE_TYPES
  return (
    <div className="AreaInput">
      {/* uncomment this for debugging */}
      {/* <pre>area: {JSON.stringify(area, undefined, 4)}</pre> */}

      <div className="inputs-and-delete">
        <div className="state-inputs">
          <OrgSelectInput
            label={stateLabel}
            options={usStateOptions}
            value={area.state}
            onChange={(newUsState) =>
              updateArea({
                state: newUsState,
                regions: [],
                cities: [],
                clinic_ids: [],
              })
            }
            isValueDisabled={isStateDisabled}
            hidden={hideCoverageType && area.state === NATIONAL}
          />

          {hideCoverageType && area.state === NATIONAL && (
            <div className="national-state">
              {area.rule_to_or_from === 'from'
                ? 'Anywhere'
                : 'Any U.S. provider'}
            </div>
          )}

          <OrgSelectInput
            options={coverageTypeOptions}
            value={area.coverage_type}
            onChange={(value) => updateArea({ coverage_type: value })}
            label="Coverage Type"
            hidden={hideCoverageType}
          />

          <OrgSelectInput
            hidden={
              area.coverage_type !== constants.COVERAGE_CITIES ||
              !area.state.length ||
              area.state === NATIONAL
            }
            options={citiesByState[area.state] || []}
            value={area.cities}
            onChange={(opts) => updateArea({ cities: opts })}
            label="Cities"
            isMulti
          />

          <OrgCreatableSelectInput
            hidden={
              area.coverage_type !== constants.COVERAGE_STATE_REGIONS ||
              !area.state.length ||
              area.state === NATIONAL
            }
            options={regionsByState[area.state] || []}
            value={area.regions}
            onChange={(opts) => updateArea({ regions: opts })}
            label="Regions"
            isMulti
          />

          <OrgSelectInput
            hidden={area.coverage_type !== constants.COVERAGE_PROVIDER}
            options={clinicsByState[area.state] || []}
            value={area.clinic_ids}
            onChange={(arrayOfIds) => updateArea({ clinic_ids: arrayOfIds })}
            label="Clinics"
            isMulti
          />
        </div>

        {(area.coverage_type !== constants.COVERAGE_ALL ||
          area.state !== NATIONAL) && (
          <div className="destroy-container">
            <button
              type="button"
              className="destroy"
              onClick={() => updateArea({ _destroy: true })}
              disabled={disableRemoveStateButton}
            >
              Remove State
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const inputType = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
)

AreaInput.propTypes = {
  area: PropTypes.shape({
    state: PropTypes.string.isRequired,
    coverage_type: PropTypes.string.isRequired,
    cities: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    clinic_ids: PropTypes.arrayOf(PropTypes.number),
    rule_to_or_from: PropTypes.string,
  }).isRequired,
  citiesByState: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  regionsByState: PropTypes.objectOf(inputType).isRequired,
  clinicsByState: PropTypes.objectOf(PropTypes.arrayOf(inputType)).isRequired,
  hideCoverageType: PropTypes.bool.isRequired,
  includeNational: PropTypes.bool,
  isStateDisabled: PropTypes.func,
}
AreaInput.defaultProps = {
  includeNational: true,
  isStateDisabled: undefined,
}

export default AreaInput
